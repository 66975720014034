.teacher-roll-form {
  width: 100%;
  height: 100vh;
  background: #0073ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  font-family: MainFont;
}

.teacher-roll-form h1 {
  color: white;
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.teacher-roll-form .form-group {
  width: 100%;
  max-width: 500px;
  margin-bottom: 15px;
}

.teacher-roll-form .form-group label {
  font-size: 1.1rem;
  color: #fff;
  margin-bottom: 10px;
  display: block;
}

.teacher-roll-form .form-group select {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-family: MainFont;
}

.teacher-roll-form button[type="button"],
.teacher-roll-form .submit-button {
  background-color: #fff;
  color: #0073ff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: MainFont;
}

.teacher-roll-form button[type="button"]:hover,
.teacher-roll-form .submit-button:hover {
  background-color: #005bb5;
  color: white;
}

.teacher-roll-form .add-grade-level,
.teacher-roll-form .add-specialization {
  display: block;
  width: 100%;
  margin-top: 15px;
  background-color: #fff;
  color: #0073ff;
  text-align: center;
  font-family: MainFont;
}

.teacher-roll-form .add-grade-level:hover,
.teacher-roll-form .add-specialization:hover {
  background-color: #005bb5;
  color: white;
}

.teacher-roll-form .remove-grade-level,
.teacher-roll-form .remove-specialization {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s ease;
  font-family: MainFont;
}

.teacher-roll-form .remove-grade-level:hover,
.teacher-roll-form .remove-specialization:hover {
  color: #c9302c;
}

.teacher-roll-form .grade-level-input,
.teacher-roll-form .specialization-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
  gap: 10px;
}

.teacher-roll-form .grade-level-input select,
.teacher-roll-form .specialization-input select {
  flex: 1;
  font-family: MainFont;
}

.teacher-roll-form .submit-button {
  width: 320px;
  height: 50px;
  border-radius: 15px;
  border: none;
  padding: 10px;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  font-family: MainFont;
  cursor: pointer;
  background-color: #00dad7;
}
