.TIM {
  width: 100%;
  height: 100vh;
  background: #0073ff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  direction: rtl;
}

.TIM .Userinfo2 {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  gap: 20px;
}
.Userinfo2 h1 {
  color: #fff;
  font-family: MainFont;
  font-weight: 400;
}

.Userinfo2 .NewInfo2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.NewInfo2 input {
  width: 300px;
  height: 30px;
  border-radius: 15px;
  border: none;
  padding: 10px;
  text-align: start;
  direction: rtl;
  outline: none;
  font-size: 20px;
  font-family: MainFont;
}

.UserInfo-button {
  width: 320px;
  height: 50px;
  border-radius: 15px;
  border: none;
  padding: 10px;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  font-family: MainFont;
  cursor: pointer;
  background-color: #00dad7;
}

.error-message {
  color: #fff;
  font-family: MainFont;
  font-weight: 400;
}
