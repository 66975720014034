/* FetchClassList.css */

/* Main container for students */
.students-container {
  padding: 20px;
  font-family: MainFont; /* Change font to MainFont */
  background-color: #007bff;
  max-width: 1200px;
  direction: rtl;
  margin: 0 auto;
}

/* Loading Spinner */
.loading {
  text-align: center;
  font-size: 1.5rem;
  color: #007bff;
  font-family: MainFont; /* Change font to MainFont */
}

/* Students list layout */
.students-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
  font-family: MainFont; /* Change font to MainFont */
}

/* Card layout for each student */
.student-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  font-family: MainFont; /* Change font to MainFont */
}

.student-card:hover {
  transform: translateY(-5px);
}

/* Header inside student card */
.student-card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.student-card-header h2 {
  font-size: 1.25rem;
  color: #007bff;
  margin: 0;
  font-family: MainFont; /* Change font to MainFont */
}

.student-card-header p {
  font-size: 1rem;
  color: #007bff;
  margin: 0;
  font-family: MainFont; /* Change font to MainFont */
}

/* Body inside student card */
.student-card-body {
  font-size: 1rem;
  color: #555;
  font-family: MainFont; /* Change font to MainFont */
}

.student-card-body p {
  margin: 8px 0;
}

ul {
  padding-left: 20px;
  margin: 0;
}

ul li {
  font-size: 0.95rem;
  color: #555;
  font-family: MainFont; /* Change font to MainFont */
}

/* Responsive design */
@media (max-width: 768px) {
  .students-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .students-list {
    grid-template-columns: 1fr;
  }
}
