.Loading {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0073ff;
}

.Loading svg {
  width: auto;
  height: 80px;
  animation: spin-and-bounce 2.5s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@keyframes spin-and-bounce {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  20% {
    transform: scale(1.05) rotate(90deg);
    opacity: 0.95;
  }
  50% {
    transform: scale(1.1) rotate(180deg);
    opacity: 0.9;
  }
  80% {
    transform: scale(1.05) rotate(270deg);
    opacity: 0.95;
  }
  100% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
}
