.VerifyEmail {
  width: 100%;
  height: 100vh;
  background: #0073ff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  gap: 50px;
}

.VerifyEmail p {
  color: #fff;
  font-size: 30px;
}
