html {
  background: #0073ff;
  font-family: MainFont;
}
.NavBar {
  width: 100%;
  height: 80px;
  background: #0073ff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}
.NavBar .UserData {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 80px;
  color: #fff;
}

.UserData img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px #fff solid;
}

.UserData h1 {
  font-size: 20px;
  font-family: MainFont;
  font-weight: 400;
}

.UserData button {
  width: 50px;
  height: 50px;
  color: #fff;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.MainInfo {
  width: 100%;
  height: 100%;
  background: #0073ff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.TopCards {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  overflow: hidden;
  direction: rtl;
}

.TopCards div {
  flex: 0 0 auto;
  width: 350px;
  height: 250px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2), 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.TopCards div h2 {
  height: fit-content;
  color: #0073ff;
  font-size: 35px;
  font-family: MainFont;
  text-decoration: underline;
  text-underline-offset: 20px;
}

.TopCards div p {
  color: #0073ff;
  font-family: MainFont;
  height: 0;
  font-size: 30px;
}

.TopCards div:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25), 0px 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.Functions {
  width: 100%;
  height: fit-content;
  background: #0073ff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  flex-direction: column;
}

.Functions h1 {
  color: #fff;
  font-family: Mainfont;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 15px;
  text-decoration-thickness: 1px;
}

.Functions select {
  width: 90%;
  direction: rtl;
  padding: 10px;
  background: #0073ff;
  border: #fff 1px solid;
  border-radius: 10px;
  color: #fff;
  font-family: MainFont;
  outline: none;
  font-size: 20px;
}

.Functions button {
  width: 90%;
  margin-top: 20px;
  height: 45px;
  border-radius: 10px;
  background: #fff;
  border: none;
  background: linear-gradient(90deg, #00e5ff, #00e6cd);
  color: #fff;
  font-family: MainFont;
  font-weight: 400;
  font-size: 25px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .TopCards {
    justify-content: start;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .TopCards div {
    scroll-snap-align: start;
  }

  .TopCards::-webkit-scrollbar {
    display: none;
  }

  .TopCards {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
