.Auth-Head {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  background-color: #0073ff;
}

.Auth-Head svg {
  width: auto;
  height: 50%;
}

.Auth-Head h1 {
  font-family: MainFont;
  font-weight: 400;
  color: #fff;
}

.Login-box {
  width: 100%;
  height: 100vh;
  background: #0073ff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
  flex-direction: column;
}

.Login-box h1 {
  color: #fff;
  font-family: MainFont;
  font-weight: 400;
}

.Login-box p {
  color: #ffffff;
  font-family: Mainfont;
  font-weight: 400;
}

.Login-box .inputs {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}

.inputs input {
  width: 300px;
  height: 30px;
  border-radius: 15px;
  border: none;
  padding: 10px;
  text-align: end;
  outline: none;
  font-size: 20px;
  font-family: MainFont;
}

.inputs button {
  width: 320px;
  height: 50px;
  border-radius: 15px;
  border: none;
  padding: 10px;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  font-family: MainFont;
  cursor: pointer;
  background-color: #00dad7;
}

.error {
  width: 320px;
  height: fit-content;
  font-family: MainFont;
  font-weight: 400;
  text-align: start;
  direction: rtl;
}

@font-face {
  font-family: MainFont;
  src: url("../../Fonts/Rubik-VariableFont_wght.ttf");
}
