.Auth-Head2 {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  background-color: #0073ff;
}

.Auth-Head2 svg {
  width: auto;
  height: 50%;
}

.Auth-Head2 h1 {
  font-family: MainFont;
  font-weight: 400;
  color: #fff;
}

.Create-box {
  width: 100%;
  height: 100vh;
  background: #0073ff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
  flex-direction: column;
}

.Create-box h1 {
  color: #fff;
  font-family: MainFont;
  font-weight: 400;
}

.Create-box p {
  color: #ffffff;
  font-family: Mainfont;
  font-weight: 400;
}

.Create-box .inputs2 {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}

.inputs2 input {
  width: 300px;
  height: 30px;
  border-radius: 15px;
  border: none;
  padding: 10px;
  text-align: end;
  outline: none;
  font-size: 20px;
  font-family: MainFont;
}

.inputs2 button {
  width: 320px;
  height: 50px;
  border-radius: 15px;
  border: none;
  padding: 10px;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  font-family: MainFont;
  cursor: pointer;
  background-color: #fe4f5a;
}

.image-upload-container {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #fe4f5a;
  transition: border-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-preview {
  min-width: 100px;
  min-height: 100px;
  object-fit: contain;
  border-radius: 50%;
}

.image-upload-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease;
}

.camera-icon {
  font-size: 30px;
  color: #555;
}

.error {
  width: 320px;
  height: fit-content;
  font-family: MainFont;
  font-weight: 400;
  text-align: start;
  direction: rtl;
}

@font-face {
  font-family: MainFont;
  src: url("../../Fonts/Rubik-VariableFont_wght.ttf");
}
